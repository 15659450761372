<template>
  <div>
    <van-nav-bar title="提现记录" left-arrow :fixed="true" :z-index="999" @click-left="back"></van-nav-bar>
    <div style="padding-top:46px;">
      <!-- <div v-for="(items,idx) in timelist" :key="idx" style="overflow: hidden"> -->
      <!-- <div class="hedst" @click="zhangkai(items,idx)">
          <div class="left">{{items.time}}</div>
          <div class="right">
            <span style="vertical-align: middle">全部</span>

            <van-icon
              style="vertical-align: middle"
              :name="items.isok == true?'arrow-down':'arrow-up'"
            />
          </div>
      </div>-->
      <div class="heds" v-for="(item,idxs) in list" :key="idxs" @click="toxiangqian(item)">
        <div style="overflow: hidden;">
          <div class="left mig">
            <img class="cimgs" src="../../assets/img/tixian.png" alt />
          </div>
          <div class style="overflow: hidden;line-height: 22px;">
            <div class="ziti">提现</div>
            <div
              class="right"
              style="color: red;font-weight: 600;"
            >+{{item.amount?item.amount.toFixed(2):'0.00'}}</div>
          </div>
          <div class="yase">
            <div class="shijian left">{{item.payTime.substr(5,11)}}</div>

            <div class="shijian right" style="font-size: 14px;">
              零钱余额
              {{item.sellBalance?item.sellBalance.toFixed(2):'0.00'}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { cashlist } from "../../api/check";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  components: {},
  // mixins: [preventBack], //注入
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      list: [], //
      timelist: [],
      time: "",
      orderNumber: "",
      active: 1,
      index: 0,
      count: "",
      token: "",
      isok: false
    };
  },
  methods: {
    zhangkai(items, idx) {
      this.index = idx;
      this.time = items.time;
      items.isok = !items.isok;
      if (items.list.length <= 0) {
        this.namelist();
      }
      console.log(items);
    },
    namelist() {
      cashlist().then(res => {
        if (res.code == 200) {
          this.list = res.data;
          //   this.timelist[this.index].list = res.data.data;
          //   console.log(this.timelist);
        } else {
          this.$toast(res.data.message);
        }
      });
    },
    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   path: "user"
      // });
    }
  },
  created() {
    this.namelist();
  },
  mounted() {
    // this.backziding();
  }
};
</script>

<style lang="less" scoped>
.heds {
  margin: 0 10px;
  border-bottom: 1px solid #f5f5f5;
  padding: 12px 0;
  font-size: 15px;
  color: #000;
}

.lis {
  font-size: 13px;
  color: #666;
  margin-top: 10px;
}

.mig {
  padding: 0 10px;
  //   width: 40px;
  min-height: 40px;
}

.yase {
  font-size: 18px;
  text-align: right;
}

.hedst {
  padding: 0 15px;
  line-height: 50px;
  height: 50px;
  font-size: 16px;
  background: #f5f5f5;
}

.shijian {
  margin-top: 12px;
  font-size: 13px;
  color: #999;
}

.ziti {
  font-size: 14px;
  float: left;
  color: #333;
}

.cimgs {
  width: 40px;
}
</style>